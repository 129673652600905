import { graphql, StaticQuery } from 'gatsby'
import PortableText from '@sanity/block-content-to-react'
import {Link} from 'gatsby'

import React from 'react'
import Layout from './layout'

import StageLinks from './stage-links.js'
import styles from './home.module.css'
import general from '../styles/general.module.css'
import SEO from '../components/seo'

import ReactTooltip from 'react-tooltip'
import { Button } from 'react-bootstrap'
import serializers from '../lib/serializers'

const query = graphql`
    query HomeQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
              title
              description
              keywords
            }
        home: sanityContentPage(directory: {eq: "/"}) {
            title
            subTitle
            _rawContentSections(resolveReferences: {maxDepth: 20})
            additionalContent {
                id
                title
                showAsAccordion
                linkListShowAs
                showIconsWithToolTip
                linkList {
                    title
                    internalLink {
                        reference {
                            ... on SanityIcon {
                                id
                                iconImage {
                                    asset {
                                        url
                                    }
                                }
                                _rawTooltipText(resolveReferences: {maxDepth: 10})
                            }
                        }
                    }
                }
            }
        }
    }
`

class StageHome extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <StaticQuery
                query={query}
                render={data => {
                    if (!data.home) {
                        throw new Error(
                            'Missing "Home". Open the Studio and some content in "Home"'
                        )
                    }
                    const siteData = data.site;
                    const homeData = data.home;
                    const contentSections = homeData._rawContentSections; 
                    const additionalContent = homeData.additionalContent;

                    const contentSectionComponents = contentSections.map((content, idx) => {
                        return (
                            <div key={idx}>
                                <h2 className="large">{content.contentSectionTitle}</h2>
                                <p>{content.contentSectionSubTitle}</p>
                                <PortableText
                                    blocks={content.paragraph}
                                    serializers={serializers}
                                    className={general.portableText}
                                />
                            </div>
                        )
                    })

                    let additionalComponents = [];

                    additionalComponents.push(additionalContent.map(content => {
                        let component = '';
                        const withToolTip = content.showIconsWithToolTip;

                        if(content.linkListShowAs === 'iconList') {
                            component = content.linkList.map(icon => {
                                const ref = icon.internalLink.reference;
                                const url = ref.iconImage.asset.url || null;
                                const tooltip = ref._rawTooltipText ? ref._rawTooltipText[0].children[0].text : null;
                                

                                return (
                                    <div key={ref.id} className={general.iconWrapper}>
                                        <img src={url} data-tip data-for={ref.id} />
                                        <p className={general.iconTitle}>{icon.title}</p>
                                        {withToolTip && <ReactTooltip id={ref.id} className={general.tooltip} multiline={true} effect='solid' >
                                            <h4>{icon.title}</h4>
                                            <p>{tooltip}</p>    
                                        </ReactTooltip>}
                                    </div>
                                )
                            })
                        }

                        return (
                            <div key={content.id} className={styles.addContentContainer}>
                                <h2 className="large">{content.title}</h2>
                                <div className={styles.addItemsContainer}>
                                    {component}
                                </div>  
                            </div>
                        )
                    }));
                    

                    return (
                        <Layout isIndex={true}>
                            <SEO
                                title={siteData.title}
                                description={siteData.description}
                                keywords={siteData.keywords}
                            />
                            <div className={styles.root}>
                                <div className={styles.descriptionContainer}>
                                    <h1 className={styles.title}>{homeData.title}</h1>
                                    <h2 className={styles.subTitle}>{homeData.subTitle}</h2>
                                    {contentSectionComponents}
                                </div>
                                <div className={general.stageLinks}>
                                        <StageLinks />
                                    </div>
                                <div className={styles.buttons}>
                                    <Link to={'/risk/1'}><Button className={general.button}>Enter guideline</Button></Link>
                                    <Link to={'/tools/tools-and-resources'}><Button className={general.button}>Tools and resources</Button></Link>
                                </div>
                                <div className={styles.addContentDiv}>
                                    {additionalComponents}
                                </div>  
                            </div>
                        </Layout>
                    )
                }
                }
            />
        )
            
    }
}

export default StageHome