import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { Link } from 'gatsby'

import styles from './stage-links.module.css'

import { FiCircle } from "react-icons/fi"

const query = graphql`
query StageLinksQuery {
    stages: sanityRiskAssessementSteps {
        steps {
            id
            title
            directory
        }
    }
  }
`

function StageLinks(props) {
    return (
        <StaticQuery
            query={query}
            render={data => {

                if (!data.stages) {
                    throw new Error(
                        'Missing "stages". Open the Studio at http://localhost:3333 and some content in "stages"'
                    )
                }

                const stagesLinks = data.stages || null;
                const stageNumber = props.stageNumber;

                const linkComponents = stagesLinks ? stagesLinks.steps.map((link, idx) => {
                    const directory = link.directory;

                    return (
                        <div className={styles.linkContainer} key={link.id}>
                            <div key={link.id} className={styles.linkWrapper}>
                                <Link to={`${directory}` + `/${idx + 1}`} className={styles.iconWrapper}>
                                    <span>{idx + 1}</span>
                                    <FiCircle className={idx + 1 <= stageNumber || !stageNumber ? styles.redIcon : styles.grayIcon} size={'45px'} />
                                </Link>
                                <p style={{ fontWeight: idx + 1 === stageNumber ? 'bold' : 'normal' }}>{link.title}</p>
                            </div>
                        </div>

                    )
                }) : null;

                return (
                    <div className={styles.root}>
                        {linkComponents}
                    </div>
                )
            }
        } />
    )
}

export default StageLinks